.application-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.app-status {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 12.5%;
    flex: 1 1 12.5%;
    max-width: 45px;
    padding: 1em 0;
    text-align: center;
    color: white;
}
  
.application-header .app-status svg {
    display: none;
}
  
.application-header[app-status-data='0'] .app-status {
    background-color: #ed2724;
}
.application-header[app-status-data='0'] .app-status .error {
    display: initial;
}

.application-header[app-status-data='1'] .app-status {
    background-color: #14a800;
}
.application-header[app-status-data='1'] .app-status .running {
    display: initial;
}

.application-header[app-status-data='2'] .app-status {
    background-color: #58595b;
}
.application-header[app-status-data='2'] .app-status .stale {
    display: initial;
}

.application-header[app-status-data='3'] .app-status {
    background-color: #ff9e22;
}
.application-header[app-status-data='3'] .app-status .api {
    display: initial;
}

.application-header .title-container {
  -webkit-box-flex: 2;
  -ms-flex: 2 1 55%;
  flex: 2 1 55%;
  padding-left: 0.75em;
}

.application-header .title {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.1em;
}

.application-header .environment {
    font-size: 0.8em;
    color: #7c7c7e;
}
  