.application {
    -ms-flex-preferred-size: 18%;
    flex-basis: 18%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin-bottom: 1.5em;
    margin-right: 2%;
    background-color: white;
    font-size: 0.9em;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1620px) {
    .application {
      -ms-flex-preferred-size: 23%;
          flex-basis: 23%;
    }
    .application:nth-child(5n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 1290px) {
    .application {
      -ms-flex-preferred-size: 31.33333%;
          flex-basis: 31.33333%;
    }
    .application:nth-child(4n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 1000px) {
    .application {
      -ms-flex-preferred-size: 48%;
          flex-basis: 48%;
    }
    .application:nth-child(3n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 675px) {
    .application {
      -ms-flex-preferred-size: 98%;
          flex-basis: 98%;
    }
    .application:nth-child(2n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 675px) {
    .application {
      margin-bottom: 1em;
    }
}
  