.module {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.8em;
    cursor: default;
}

.module svg {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    font-size: 1.2em;
    display: none;
}
  
.module[module-status-data='HOME'] .home {
    color: #214871;
    display: initial;
}

.module[module-status-data='LINK'] .link {
    color: #214871;
    display: initial;
}

.module[module-status-data='ERROR'] .error {
    color: #ed2724;
    display: initial;
}

.module[module-status-data='RUNNING'] .running {
    color: #14a800;
    display: initial;
}

.module[module-status-data='WARNING'] .warning {
    color: #14a800;
    display: initial;
}

.module[module-status-data='INFO'] .info {
    color: #14a800;
    display: initial;
}

.module[module-status-data='SERVER'] .server {
    color: #214871;
    display: initial;
}

.module .module-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 37.5%;
    flex: 1 1 37.5%;
}
  
.module hr {
    border: none;
    margin: 0;
    border-left: 1px dashed #a0a3a5;
    height: 2.5em;
    width: 1px;
}
  
.module .module-description {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    padding-left: 1em;
}
  
.module .module-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 37.5%;
    flex: 1 1 37.5%;
    cursor: pointer;
    text-overflow: ellipsis;
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
}

.module:nth-child(odd) {
    background-color: rgba(235, 235, 235, 0.75);
}
