.app-grid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 80%;
    flex: 1 0 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 3.5%;
    padding-left: 2.5%;
    padding-right: 0.5%;
    max-height: calc(100% + 20px);
    overflow: auto;
    position: relative;
    top: -20px;
}
  